<template>
  <div class="bg-neutral-100 py-14 lg:py-24">
    <div
      ref="sectionRef"
      class="grid-default container gap-y-6 lg:min-h-screen lg:gap-y-20"
      :class="[relatedArticles.length > 1 && 'items-end']"
    >
      <div ref="titleRef" class="col-span-6 self-start">
        <Heading v-if="data.title" semantic class="mb-6 text-3xl lg:mb-7 lg:text-6xl">
          {{ data.title }}
        </Heading>

        <template v-for="category in data.categories" :key="category.id">
          <button
            class="mb-8 ml-1 hidden rounded-full px-5 py-[6px] text-2xl font-medium text-blue-600 transition-colors duration-300 first-of-type:ml-1 hover:bg-light-gray lg:inline-block"
            :class="{ 'bg-light-gray': selectedCategory?.id === category.id }"
            @click="selectCategory(category)"
          >
            {{ category.name }}
          </button>
        </template>

        <div v-if="data.button?.length">
          <Button
            v-for="button in data.button"
            :key="button.id"
            variant="solid"
            :label="button.title"
            :target="button.externalLink ? '_blank' : undefined"
            :to="
              button.pageLink !== null
                ? localeRoute({
                    path: paths[locale as LocaleCode][ModelApiKey.Article].path,
                    query: { category: selectedCategory?.name }
                  })
                : button.externalLink
            "
            :color="button.primary ? 'default' : 'blue'"
            icon
          />
        </div>
      </div>

      <div v-if="!isDesktop" class="col-span-6 -mx-4">
        <LazyRelatedArticlesMobile :related-articles="relatedArticles" />
      </div>

      <template v-if="isDesktop">
        <template v-for="(article, index) in relatedArticles" :key="article.id">
          <div ref="imagesRefs" class="col-span-6 relative" :class="index === 0 ? 'text-5xl' : 'text-4xl'">
            <div v-if="article.category.length" class="flex gap-1">
              <div
                v-for="category in article.category"
                :key="category.id"
                class="mb-3 w-fit rounded-full bg-white px-3 py-2 text-xs"
              >
                {{ category.name }}
              </div>
            </div>

            <div class="mb-6 text-blue-800">
              {{ article.title }}
            </div>

            <NuxtLink
              :to="localePathByType('ArticleRecord', article.slug as string)"
            >
              <div class="absolute bottom-12 right-12 z-2 h-16 w-16">
                <CircleButton class="!bg-none" icon-name="arrow-right" color="orange" />
              </div>
              <Image
                class="aspect-4/3 rounded-3xl object-cover"
                loading="lazy"
                :src="
                  article.image?.responsiveImage
                    ? article.image?.responsiveImage?.src
                    : article.image?.url
                "
                :srcset="
                  article.image?.responsiveImage
                    ? article.image?.responsiveImage?.webpSrcSet
                    : undefined
                "
                :width="
                  article.image?.responsiveImage
                    ? article.image?.responsiveImage?.width
                    : article.image?.width
                "
                :height="
                  article.image?.responsiveImage
                    ? article.image?.responsiveImage?.height
                    : article.image?.height
                "
                :sizes="article.image?.responsiveImage?.sizes"
                :alt="article.image?.alt!"
              />
            </NuxtLink>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import type {
  ArticleRecord,
  CategoryRecord,
  SectionRelatedArticlesListRecord
} from '~/types/generated'
import { paths } from '~/lib/paths'
import { LocaleCode } from '~/types/locales'
import { ModelApiKey } from '~/types/models'
import { theme } from '#tailwind-config'

const props = defineProps({
  data: {
    type: Object as PropType<SectionRelatedArticlesListRecord>,
    default: () => {}
  }
})

const breakpoints = useBreakpoints(theme.screens)
const isScreenLg = breakpoints.greater('lg')
const localeRoute = useLocaleRoute()
const { localePathByType } = useLocalePathByType()
const { isDesktop } = useDevice()
const { locale } = useI18n()

const titleRef = ref<HTMLElement | null>()
const imagesRefs = ref<HTMLElement[] | null>()
const sectionRef = ref<HTMLElement | null>()
const relatedArticles = ref<ArticleRecord[]>([])

const isPinned = ref(false)
const selectedCategory = ref()

const filterAny = computed(() => {
  return props.data.categories?.length ? props.data.categories.map((x: { id: any }) => x.id) : []
})

const articlesCount = ref(3)

watch(selectedCategory, async () => {
  const { data: articles } = await useAsyncGql('articles', {
    // @ts-ignore
    locale,
    first: articlesCount.value,
    anyIn: selectedCategory.value.id
  })

  if (articles.value) {
    relatedArticles.value = articles.value.allArticles
  }
})

const { data: articles } = await useAsyncGql('articles', {
  // @ts-ignore
  locale,
  first: articlesCount.value,
  anyIn: filterAny
})

if (articles.value) {
  relatedArticles.value = articles.value.allArticles
}

// Add CMS Overrides
if (Array.isArray(props.data.overrideArticles) && props.data.overrideArticles.length) {
  relatedArticles.value.unshift(...props.data.overrideArticles)
  relatedArticles.value = relatedArticles.value.slice(0, articlesCount.value)
}

function selectCategory(category: CategoryRecord) {
  selectedCategory.value = category
}

const { stop } = useIntersectionObserver(
  sectionRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      if (!isPinned.value) {
        if (titleRef.value && imagesRefs.value && isScreenLg.value) {
          ScrollTrigger.create({
            pin: titleRef.value,
            start: 'top top',
            end: () => {
              return '+=' + imagesRefs.value![0].clientHeight / 1.5
            }
          })
        }

        ScrollTrigger.refresh()
      }

      isPinned.value = true
    }
  },
  {
    threshold: 0
  }
)

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger)
})

onUnmounted(() => {
  stop()

  ScrollTrigger.getAll().forEach(trigger => {
    trigger.kill()
  })
})
</script>
